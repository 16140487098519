<template>
    <div class="field">
        <label v-if="label">{{ label }}</label>
        <textarea @input="updateValue($event.target.value)" :placeholder="placeholder"/>
    </div>
</template>

<script>
    export default {
        name: 'TextareaField',

        props: [
            'value',
            'label',
            'placeholder'
        ],

        methods: {
            updateValue(value) {
                this.$emit('input', value.length > 0 ? value : null)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        border: none;
        height: unset;
        min-height: 120px;

        textarea {
            border: 1px solid $timberGreen;
            width: 100%;
            height: calc(100% - 20px);
            line-height: 28px;
            padding: 10px;

            font-size: getFontSize('smallText', 'desktop');
            font-family: getFontFamily('smallText');
            font-weight: getFontWeight('smallText');

            @include breakpoint('tablet') {
                font-size: getFontSize('smallText', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('smallText', 'mobile');
            }

            &:focus-visible {
                border: 1px solid $timberGreen;
                box-shadow: none;
                outline: none;
            }
        }
    }
</style>
